import React from "react"
import styled from "styled-components"
import {
  Column,
  Container,
  Heading,
  Icon,
  Paragraph,
  Row,
  ServiceList,
  Spacer,
} from "../../components/components"

const Services = () => {
  return (
    <Container>
      <ServicesWrapper>
        <Row>
          <Column style={{ padding: "0px 32px" }}>
            <Heading tag="h1" id="individuelle-softwareentwicklung">
              Individuelle Softwareentwicklung{" "}
            </Heading>
            <Paragraph>
              Standardsoftware erreicht schnell ihre Grenzen, wenn
              unternehmensspezifische Prozesse abgebildet werden sollen. Damit
              Ihr Unternehmen sich nicht an diese Produkte anpassen muss,
              entwickeln wir maßgeschneiderte Applikationen, die exakt auf Ihre
              Anforderungen abgestimmt sind und sich kontinuierlich den
              Veränderungen Ihres Unternehmens anpassen. Unsere Lösungen sind
              flexibel, skalierbar und sicher.{" "}
            </Paragraph>
          </Column>
        </Row>
        <ServiceList
          entries={[
            {
              icon: () => <WebIcon />,
              color: "blue",
              title: "Webapplikationen",
              content:
                "Wir entwickeln für Sie intuitive Applikationen auf Grundlage moderner Webtechnologien. Unabhängig von Geräten und Betriebssystemen ermöglichen wir Ihren Benutzern Lösungen, die einfach und zuverlässig funktionieren.",
            },
            {
              icon: () => <MobileIcon />,
              color: "red",
              title: "Mobile Applikationen",
              content:
                "Um Ihren Benutzern die Möglichkeit zu geben, von Ihrem Endgerät aus weltweit auf Ihre Lösungen zugreifen zu können, entwickeln für Sie native sowie hybride mobile Apps für Smartphones, Tablets, Smartwatches und Smart-TVs – zugeschnitten auf Ihre Bedürfnisse.",
            },
            {
              icon: () => <ArchitectureIcon />,
              color: "purple",
              title: "Lösungsarchitekturen",
              content:
                "Mit unserer Expertise im Bereich der Modellierung von Systemarchitekturen, unterstützen wir Sie dabei, Ihre Unternehmensprozesse ganzheitlich abzubilden und Integrationen in Cloud-, Edge- und OnPremise-Infrastruktur zu ermöglichen.",
            },
            {
              icon: () => <IntegrationIcon />,
              color: "yellow",
              title: "System Integrationen",
              content:
                "Zuverlässige Integrationen in bereits genutzte oder zukünftig gewünschte SaaS-Ökosysteme wie Microsoft 365, Salesforce oder SAP ermöglichen nahtlose Übergänge Ihrer Prozesse in maßgeschneiderte Softwarelösungen und zurück.",
            },
          ]}
        />
        <Spacer gutter={40} />
        <Row style={{ padding: "0 8px" }}>
          <Column style={{ padding: "0px 32px" }}>
            <Heading tag="h1" id="cloud-engineering">
              Cloud Engineering{" "}
            </Heading>
            <Paragraph>
              Die Vorteile der Cloud in Bereichen wie Flexibilität, Elastizität
              und Agilität gilt es heute zu nutzen, um in den Märkten von morgen
              durchzustarten. Mit unserer Expertise für zeitgemäße Cloudlösungen
              stehen wir Ihnen als zuverlässiger Partner zur Verfügung.{" "}
            </Paragraph>
          </Column>
        </Row>
        <ServiceList
          entries={[
            {
              icon: () => <CloudArchitectureIcon />,
              color: "blue",
              title: "Cloud Architektur",
              content:
                "Angefangen von in der Cloud betriebenen Webapplikationen, über umfangreich orchestrierte Microservicearchitekturen, bis hin zu Event-basierten Serverless-Landschaften, sind wir Ihr Partner für die Abbildung Ihrer zukunftsorientierten, sicheren und skalierbaren Cloudstrategie.",
            },
            {
              icon: () => <CloudMigrationIcon />,
              color: "red",
              title: "Cloud Migration",
              content:
                "Auf dem Weg in die Cloud gilt es, bisherige Infrastruktur und Lösungen für diese Reise vorzubereiten. Sei es im Bereich von ‚lift-and-shift‘ Migrationen von OnPremise-betriebenen Applikationen, der Überführung der Benutzer- und Berechtigungsverwaltung mit ‚Secure single sign-on‘ und ‚Multi-factor authentication‘ oder die Neugestaltung von Applikationen und Prozessen. Wir schreiben die Checkliste und packen Ihre Koffer.",
            },
            {
              icon: () => <HybridSolutionIcon />,
              color: "purple",
              title: "Hybrid Lösungen",
              content:
                "Der Verbleib sicherheitsrelevanter Systeme in der eigenen Infrastruktur stellt heute keinen Hinderungsgrund für eine erfolgreiche Cloudadaption mehr dar. Mit hybriden Lösungen profitieren Sie von der Geschwindigkeit und Flexibilität der Cloud für neue oder weniger eingeschränkte Bereiche, ohne einen Bruch Ihres Gesamtsystems befürchten zu müssen.",
            },
          ]}
        />
        <Spacer gutter={40} />
        <Row style={{ padding: "0 8px" }}>
          <Column style={{ padding: "0px 32px" }}>
            <Heading tag="h1" id="devOps">
              DevOps
            </Heading>
            <Paragraph>
              Die Entwicklung und den Betrieb von Software zusammenzuführen
              ermöglicht Ihnen neue Möglichkeiten, angefangen bei der enormen
              Geschwindigkeit, Ihre Benutzer mit neuen Features auszustatten bis
              hin zu umfangreichen Reporting- und Monitoring-Fähigkeiten.
            </Paragraph>
          </Column>
        </Row>
        <Row>
          <Column>
            <ServiceList
              entries={[
                {
                  icon: () => <IntegrationsIcon />,
                  color: "blue",
                  title: "Continuous Integration & Delivery",
                  content:
                    "Um Ihre Benutzer von aktuellen Weiterentwicklungen und Anpassungen schnellstmöglich profitieren zu lassen, gehört die kontinuierliche Bereitstellung ebendieser Neuerungen zu unserer Philosophie. Hierbei gehen wir natürlich keine Kompromisse in den Bereichen Sicherheit und Qualität ein.",
                },
                {
                  icon: () => <InfrastuctureIcon />,
                  color: "red",
                  title: "Infrastruktur Automation",
                  content:
                    "Vorbei sind die Zeiten, in denen fertige Software auf die Provisionierung von Servern und Infrastruktur warten musste. Integriert in unsere CI/CD Strategie, erarbeiten wir mit modernen ‚infrastructure as code‘ Lösungen, die sofortige Bereitstellung von Applikationen in der gewünschten Laufzeitumgebung.",
                },
                {
                  icon: () => <MonitoringIcon />,
                  color: "purple",
                  title: "Überwachung & Alarmierung",
                  content:
                    "Für den reibungslosen Betrieb Ihrer Systeme erarbeiten wir die für Sie passenden Integrationen im Bereich von Protokollierung und Rückverfolgbarkeit von Prozessen und relevanten Leistungsmetriken Ihrer Systeme. Hieraus ergeben sich für Sie die Erstellung von Reports und automatisierte Alarmierung von relevanten Personenkreisen.",
                },
              ]}
            />
          </Column>
        </Row>
        <Spacer gutter={40} />
        <Row style={{ padding: "0 8px" }}>
          <Column style={{ padding: "0px 32px" }}>
            <Heading tag="h1" id="consulting">
              Consulting
            </Heading>
            <Paragraph>
              Die vertrauensvolle Beratung unserer Kunden ist uns seit dem
              ersten Tag wichtig. Nur wenn Sie über präzise Informationen zu
              Möglichkeiten und Aufwänden verfügen, können Sie zuverlässige und
              fundierte Entscheidungen treffen.
            </Paragraph>
          </Column>
        </Row>
        <ServiceList
          entries={[
            {
              icon: () => <RequirementIcon />,
              color: "blue",
              title: "Anforderungsmanagement",
              content:
                "Wir unterstützen unsere Kunden bei der Identifikation von Anforderungen, sowie der Formulierung von Umsetzungswünschen und Akzeptanzkriterien. So stellen wir gemeinsam mit Ihnen sicher, dass Sie eine Lösung erhalten, die zu Ihrer Herausforderung passt.",
            },
            {
              icon: () => <ProjectManagementIcon />,
              color: "red",
              title: "Projektmanagement",
              content:
                "Unabhängig davon, ob Sie das erste Mal eine individuelle Softwarelösung beauftragen oder bereits Erfahrung in der Gestaltung von Entwicklungsprojekten haben. Wir begleiten Sie bei jedem Schritt auf dem Weg zu Ihrer Lösung und stellen gemeinsam mit Ihnen sicher, dass Sie zu jedem Zeitpunkt Einblick und Einfluss in die Entwicklung nehmen können, um zuverlässig und schnell an das gewünschte Ziel zu kommen.",
            },
            {
              icon: () => <DesignConceptIcon />,
              color: "purple",
              title: "Architektur Design Konzepte",
              content:
                "Eine Vorbedingung für eine ganzheitliche Lösung ist die Modellierung von allen Elementen eines Systems sowie ihren Abhängigkeiten, konzeptionellen Grenzen und Übergängen. Mit unserer Erfahrung im Bereich von Architekturkonzepten, Industriestandards und ‚best-practices‘, beraten wir Sie gerne bei diesem wichtigen ersten Schritt auf Ihrem Weg in eine zukunftsfähige Gesamtlösung, die sich kontinuierlich an Ihre Herausforderungen anpasst.",
            },
          ]}
        />
      </ServicesWrapper>
    </Container>
  )
}

export default Services

const ServicesWrapper = styled.div`
  *[id] {
    scroll-margin-top: 100px;
  }
`

const WebIcon = styled(Icon.Web)`
  width: 24px;
  height: 24px;
`
const MobileIcon = styled(Icon.Mobile)`
  width: 24px;
  height: 24px;
`
const ArchitectureIcon = styled(Icon.Architecture)`
  width: 24px;
  height: 24px;
`
const IntegrationIcon = styled(Icon.Integration)`
  width: 24px;
  height: 24px;
`
const CloudArchitectureIcon = styled(Icon.CloudArchitecture)`
  width: 24px;
  height: 24px;
`
const CloudMigrationIcon = styled(Icon.CloudMigration)`
  width: 24px;
  height: 24px;
`
const HybridSolutionIcon = styled(Icon.HybridSolution)`
  width: 24px;
  height: 24px;
`
const InfrastuctureIcon = styled(Icon.Infrastucture)`
  width: 24px;
  height: 24px;
`
const IntegrationsIcon = styled(Icon.ConIntegration)`
  width: 24px;
  height: 24px;
`
const MonitoringIcon = styled(Icon.Monitoring)`
  width: 24px;
  height: 24px;
`
const DesignConceptIcon = styled(Icon.DesignConcept)`
  width: 24px;
  height: 24px;
`
const ProjectManagementIcon = styled(Icon.ProjectManagement)`
  width: 24px;
  height: 24px;
`
const RequirementIcon = styled(Icon.Requirement)`
  width: 24px;
  height: 24px;
`
