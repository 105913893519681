import React from "react"
import {
  Head,
  SecondarySection,
  Paragraph,
  Spacer,
  Container,
  Heading,
  Row,
  Column,
  Section,
  Emphasized,
} from "../../components/components"

import Services from "../../widgets/Services/Services"
import CallToAction from "../../widgets/CallToAction/CallToAction"
import styled from "styled-components"

const ServicesPage = ({}: { data: any }) => {
  return (
    <>
      <Head pagePath="/leistungen" />
      <Section>
        <Container>
          <Row>
            <Column style={{ alignItems: "center", padding: "0 24px" }}>
              <Heading tag="h1" style={{ textAlign: "center" }}>
                Leistungen, von denen unsere <Emphasized>Kunden </Emphasized>
                profitieren
              </Heading>
            </Column>
          </Row>
          <Spacer gutter={16} />

          <Flex>
            <Paragraph textAlign="center">
              Jede Organisation ist einzigartig. Für ein Unternehmen könnte
              Zukunftsfähigkeit bedeuten, ein neues, digitales Produkt zu
              entwickeln. Für ein anderes könnte es eine Optimierung der
              bestehenden Arbeitsweise sein. Wir sind Experten für Strategie,
              Design und Softwareentwicklung und kombinieren diese Fähigkeiten,
              um Organisationen auf jeder Ebene zu verändern.
            </Paragraph>
          </Flex>
        </Container>
      </Section>
      <Spacer gutter={40} />
      <SecondarySection>
        <Services />
      </SecondarySection>
      {/* <ContactForm /> */}
      <CallToAction type="customer" />
    </>
  )
}

export default ServicesPage

const Flex = styled.div`
  display: flex;
  justify-content: center;
`
